// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._7jRv{padding-top:1.6rem}._2hMT{padding-bottom:3.2rem;display:flex;flex-direction:column;justify-content:space-between;cursor:pointer}._2hMT,._2hMT:hover{color:#000}._3trO{margin-top:2.4rem}._1C5u{text-align:center}._2z8I{height:335px;position:relative}.qGsP{-o-object-fit:cover;object-fit:cover;width:100%;height:100%}._2tb2,.qGsP{position:absolute}._2tb2{background-color:#fff;padding:.8rem 1rem;bottom:0;left:0}._1UJ4{position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);color:#fcd700}.hGlh{display:flex;flex-direction:column;justify-content:space-between}@media (max-width:35.9375em){._1En4{font-size:1.6rem;font-size:var(--font-size-p2,1.6rem)}}._2FKL{align-self:flex-end;margin-top:1.6rem}@media (max-width:35.9375em){._2FKL{display:none}}html[dir=rtl] ._2tb2{left:auto;right:0}", ""]);
// Exports
exports.locals = {
	"wrapper": "_7jRv",
	"news": "_2hMT",
	"newsList": "_3trO",
	"header": "_1C5u",
	"imageWrapper": "_2z8I",
	"image": "qGsP",
	"date": "_2tb2",
	"playIcon": "_1UJ4",
	"content": "hGlh",
	"text": "_1En4",
	"readMore": "_2FKL"
};
module.exports = exports;
