





































































import Vue, { PropType } from 'vue'
import UiVideoOverlay from '../UiVideoOverlay'
import UiIcon from '../UiIcon'
import UiImage from '../UiImage'
import { NewsList } from './UiNewsList.types'

export default Vue.extend({
  name: 'UiNewsList',
  components: {
    UiVideoOverlay,
    UiIcon,
    UiImage
  },
  props: {
    list: {
      type: Array as PropType<NewsList>,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    headerTag: {
      type: String,
      default: 'h2'
    }
  },
  data () {
    return {
      selectedVidId: '',
      showVideoOverlay: false
    }
  },
  methods: {
    showVideo (ytId: string): void {
      this.selectedVidId = ytId
      this.showVideoOverlay = true
    },
    closeOverlay (): void {
      this.showVideoOverlay = false
    }
  }
})
